<template>
  <div id="app">
    <div>
      <mdb-navbar position="top" dark color="blue-gradient" scrolling>
        <mdb-navbar-brand href="#">DREWES.News</mdb-navbar-brand>
        <mdb-navbar-toggler>
          <mdb-navbar-nav>
            <mdb-nav-item href="#" waves-fixed>Home</mdb-nav-item>
            <mdb-dropdown tag="li" class="nav-item">
              <mdb-dropdown-toggle
                tag="a"
                navLink
                slot="toggle"
                waves-fixed
                >Filter</mdb-dropdown-toggle
              >
              <mdb-dropdown-menu multiLevel @click.native.stop>
                <mdb-container>
                  <mdb-btn outline="default" size="sm" name="btn-clear" @click.native.stop v-on:click="toggleChecks()">Check {{allNoneText()}}</mdb-btn>
                  <mdb-btn color="success" size="sm" name="btn-filter" v-on:click="updateFilter()">Filter <mdb-icon icon="filter" /></mdb-btn>
                </mdb-container>
                <mdb-container style="height: 600px; overflow: auto;" @click.native.stop>
                  <mdb-row>
                    <mdb-col>
                      <h6 class="dropdown-header">Category</h6>
                      <div class="custom-control custom-checkbox" @click.stop>
                      <mdb-dropdown-item>
                            <input type="checkbox" class="custom-control-input" id="worldNewsFilter" v-model="checkedCategories" value="World News" @change="$emit('click', $event)">
                            <label class="custom-control-label" for="worldNewsFilter">World News</label>
                      </mdb-dropdown-item>
                      <mdb-dropdown-item>
                            <input type="checkbox" class="custom-control-input" id="politicsFilter" v-model="checkedCategories" value="Politics" @change="$emit('click', $event)">
                            <label class="custom-control-label" for="politicsFilter">Politics</label>
                      </mdb-dropdown-item>
                      <mdb-dropdown-item>
                            <input type="checkbox" class="custom-control-input" id="entertainmentFilter" v-model="checkedCategories" value="Entertainment" @change="$emit('click', $event)">
                            <label class="custom-control-label" for="entertainmentFilter">Entertainment</label>
                      </mdb-dropdown-item>
                      <mdb-dropdown-item>
                            <input type="checkbox" class="custom-control-input" id="sportsFilter" v-model="checkedCategories" value="Sports" @change="$emit('click', $event)">
                            <label class="custom-control-label" for="sportsFilter">Sports</label>
                      </mdb-dropdown-item>
                      <mdb-dropdown-item>
                            <input type="checkbox" class="custom-control-input" id="businessFilter" v-model="checkedCategories" value="Business" @change="$emit('click', $event)">
                            <label class="custom-control-label" for="businessFilter">Business</label>
                      </mdb-dropdown-item>
                      <mdb-dropdown-item>
                            <input type="checkbox" class="custom-control-input" id="scienceFilter" v-model="checkedCategories" value="Science" @change="$emit('click', $event)">
                            <label class="custom-control-label" for="scienceFilter">Science</label>
                      </mdb-dropdown-item>
                      <mdb-dropdown-item>
                            <input type="checkbox" class="custom-control-input" id="crimeFilter" v-model="checkedCategories" value="Crime" @change="$emit('click', $event)">
                            <label class="custom-control-label" for="crimeFilter">Crime</label>
                      </mdb-dropdown-item>
                      </div>
                    </mdb-col>
                    <mdb-col>
                      <h6 class="dropdown-header">News Source</h6>
                      <mdb-row>
                        <mdb-col>
                          <div class="custom-control custom-checkbox" v-for="source in sources.slice(0,12)" :key="source.code" @click.stop>
                          <mdb-dropdown-item>
                                <input type="checkbox" class="custom-control-input" :id="source.code" :value="source.code" v-model="checkedSources" @change="$emit('click', $event)">
                                <label class="custom-control-label" :for="source.code">{{source.name}}</label>
                          </mdb-dropdown-item>
                          </div>
                        </mdb-col>
                        <mdb-col>
                          <div class="custom-control custom-checkbox" v-for="source in sources.slice(12,24)" :key="source.code" @click.stop>
                          <mdb-dropdown-item>
                                <input type="checkbox" class="custom-control-input" :id="source.code" :value="source.code" v-model="checkedSources" @change="$emit('click', $event)">
                                <label class="custom-control-label" :for="source.code">{{source.name}}</label>
                          </mdb-dropdown-item>
                          </div>
                        </mdb-col>
                        <mdb-col>
                          <div class="custom-control custom-checkbox" v-for="source in sources.slice(24,37)" :key="source.code" @click.stop>
                          <mdb-dropdown-item>
                                <input type="checkbox" class="custom-control-input" :id="source.code" :value="source.code" v-model="checkedSources" @change="$emit('click', $event)">
                                <label class="custom-control-label" :for="source.code">{{source.name}}</label>
                          </mdb-dropdown-item>
                          </div>
                        </mdb-col>
                      </mdb-row>
                    </mdb-col>
                  </mdb-row>
                </mdb-container>
              </mdb-dropdown-menu>
            </mdb-dropdown>
          </mdb-navbar-nav>
        </mdb-navbar-toggler>
      </mdb-navbar>
    </div>
    <div class="content">
      <router-view :do-refresh="doRefresh" :checked-categories="checkedCategories" :checked-sources="checkedSources" v-on:refreshed="doRefresh = false" />
    </div>
  </div>
</template>

<script>
import {
    mdbNavbar,
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbNavItem,
    mdbIcon,
    mdbNavbarNav,
    mdbNavbarToggler,
    mdbDropdown,
    mdbDropdownItem,
    mdbDropdownMenu,
    mdbDropdownToggle,
    mdbNavbarBrand
  } from "mdbvue";
export default {
  name: 'App',
  components: {
    mdbNavbar,
    mdbNavItem,
    mdbIcon,
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbNavbarNav,
    mdbNavbarToggler,
    mdbDropdown,
    mdbDropdownItem,
    mdbDropdownMenu,
    mdbDropdownToggle,
    mdbNavbarBrand
  },
  data () {
    return {
      doRefresh: false,
      checkedCategories: ["World News", "Politics", "Sports", "Entertainment", "Business", "Science", "Crime"],
      sources: [
        {name: "CNN", code: "CNN"},
        {name: "CBS", code: "CBS"},
        {name: "ABC", code: "ABC"},
        {name: "Boston Herald", code: "BH"},
        {name: "Breitbart", code: "BREITBART"},
        {name: "The Christian Science Monitor", code: "CHRISTSCI"},
        {name: "The Daily Caller", code: "DAILYCALLER"},
        {name: "Deadline", code: "DEADLINE"},
        {name: "Fox News", code: "FOX"},
        {name: "The Washington Free Beacon", code: "WFB"},
        {name: "Free Republic", code: "FR"},
        {name: "The Gateway Pundit", code: "TGP"},
        {name: "The Huffington Post", code: "HUFFPO"},
        {name: "Info Wars", code: "INFO"},
        {name: "The Intercept", code: "INT"},
        {name: "Mediaite", code: "MEI"},
        {name: "Mother Jones", code: "MJ"},
        {name: "The Nation", code: "NATION"},
        {name: "National Review", code: "NR"},
        {name: "The New Republic", code: "TNR"},
        {name: "New York Magazine", code: "NYMAG"},
        {name: "New York Daily News", code: "NYDN"},
        {name: "New York Post", code: "NYP"},
        {name: "New York Times", code: "NYT"},
        {name: "New Yorker", code: "NYORKER"},
        {name: "Newsmax", code: "NMAX"},
        {name: "Politico", code: "POL"},
        {name: "Real Clear Politics", code: "RCP"},
        {name: "USA Today", code: "USA"},
        {name: "Wall Street Journal", code: "WSJ"},
        {name: "Washington Examiner", code: "WASH"},
        {name: "Washington Times", code: "WATIMES"},
        {name: "WND", code: "WND"}
      ],
      checkedSources: [
        "CNN",
        "CBS",
        "ABC",
        "BH",
        "BREITBART",
        "CHRISTSCI",
        "DAILYCALLER",
        "DEADLINE",
        "FOX",
        "WFB",
        "FR",
        "TGP",
        "HUFFPO",
        "INFO",
        "INT",
        "MEI",
        "MJ",
        "NATION",
        "NR",
        "TNR",
        "NYMAG",
        "NYDN",
        "NYP",
        "NYT",
        "NYORKER",
        "NMAX",
        "POL",
        "RCP",
        "USA",
        "WSJ",
        "WASH",
        "WATIMES",
        "WND"
      ]
    }
  },
  methods: {
    updateFilter: function() {
      this.doRefresh = true;
    },
    allNoneText: function() {
      if (this.checkedSources.length == this.sources.length) {
        return "None";
      }
      return "All";
    },
    toggleChecks: function() {
      if (this.checkedSources.length != this.sources.length) {
        this.checkedSources = [];
        for (var source in this.sources) {
          this.checkedSources.push(this.sources[source].code);
        }
      } else {
        this.checkedSources = [];
      }
    }
  }
}
</script>
<style lang="scss">
$image-path: '~@/../mdb/mdbvue/img';
@import '~@/../mdb/mdbvue/scss/mdb-free.scss';

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

#app {
  margin-top: 15px;
}
.content {
padding-top: 80px;
}
.bigmenu {
  min-width: 675px;
}

</style>
