<template>
    <mdb-container>
        <div class="d-flex justify-content-center">
            <div class="spinner-grow text-success position-absolute" role="status" style="width: 5rem; height: 5rem; margin-top:150px;" v-if="loading">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <template>
            <div>
                <mdb-row>
                    <mdb-col lg="4" xl="3">
                        <mdb-view class="rounded z-depth-1-half mb-lg-0 mb-4" hover>
                            <img class="img-fluid crop" :src="bestImage(story.story)" alt="Story Image"/>
                            <a>
                                <mdb-mask overlay="white-slight" waves/>
                            </a>
                        </mdb-view>
                    </mdb-col>
                    <mdb-col lg="8" xl="9">
                        <a class="indigo-text">
                            <h6 class="font-weight-bold mb-3">
                            <mdb-icon v-if="'category' in story.story" :icon="categoryToIcon(story.story.category)" class="pr-2" />{{story.story.category}}</h6>
                        </a>
                        <span class="font-weight-bold dark-grey-text">
                            {{story.story.published}}
                        </span>
                        <p>
                            {{story.story.rss_source}}
                        </p>
                        <h3 class="font-weight-bold dark-grey-text mb-3 p-0">
                            <strong>{{story.story.title}}</strong>
                        </h3>
                        <a :href="story.story.link" target="_blank" rel="noopener noreferrer"><mdb-btn color="indigo" size="md" class="waves-light">Read more</mdb-btn></a>
                    </mdb-col>
                </mdb-row>
                <mdb-row>
                    <div v-if="'word_scores' in story.story" class="wordscores">
                        <span v-for="word in parseJson(story.story.word_scores)" :key=word.M.word.S>
                            // {{word.M.word.S}} ({{Math.round(word.M.score.N * 100)}}%)
                        </span>
                    </div>
                </mdb-row>         
                <mdb-row v-for="rel in getSubStories(story.story.similar_stories)" :key="rel.story_id">
                    <mdb-col md="2" class="text-sm-right">
                        <mdb-badge color="default">{{rel.rss_source}}</mdb-badge> <mdb-badge :color="percentColor(Math.round((rel.sim_distance) * 100))" v-if="'sim_distance' in rel">{{Math.round((rel.sim_distance) * 100)}}% match</mdb-badge>
                    </mdb-col>
                    <mdb-col md="10" style="padding-left: 0px">
                        <a :href="rel.link" target="_blank" rel="noopener noreferrer">{{rel.title}}</a> - {{rel.published}}
                    </mdb-col>
                </mdb-row>
                <hr class="my-5" />
            </div>
            <div>
                <mdb-row>
                    <mdb-col>
                        <h6 class="bg-light p-2 border-top border-bottom">Term Frequencies</h6>
                        <mdbCard style="width: 14rem; height: 250px; overflow-x: scroll">
                            <mdbListGroup small light flush v-for="word_count in story.story.word_counts.sort((a,b) => b[1] - a[1])" :key="word_count[0]">
                                <mdbListGroupItem class="d-flex justify-content-between align-items-center small">{{ word_count[0] }}<mdbBadge class="badge-primary" pill>{{ word_count[1] }}</mdbBadge></mdbListGroupItem>
                            </mdbListGroup>
                        </mdbCard>
                    </mdb-col>
                    <mdb-col>
                        <h6 class="bg-light p-2 border-top border-bottom">Other Meta Information</h6>
                    </mdb-col>
                </mdb-row>
            </div>
        </template>
    </mdb-container>
</template>

<script>
import Axios from 'axios'
import { mdbContainer, mdbRow, mdbCol, mdbMask, mdbIcon, mdbView, mdbBtn,  mdbCard, mdbListGroup, mdbListGroupItem, mdbBadge } from 'mdbvue';
const JSON5 = require('json5')
export default {
  name: 'Home',
    components: {
        mdbContainer,
        mdbRow,
        mdbCol,
        mdbMask,
        mdbIcon,
        mdbView, mdbCard, mdbListGroup, mdbListGroupItem,
        mdbBadge,
        mdbBtn
    },
  data () {
    return {
      story: "",
      loading: false
    }
  },
  methods: {
      percentColor: function(percentVal) {
          if (percentVal >= 60) return "success";
          if (percentVal >= 40) return "warning";
          return "danger";
      },
      parseJson: function(json) {
          try {
            return JSON5.parse(json);
          } catch {
              console.log("Unable to read JSON:: " + json);
          }
      },
      getSubStories: function(stories) {
          if (stories.length <= 1) { return []; }
          
          return stories;
      },
      categoryToIcon: function(category) {
          var cat = category.toLowerCase()
          if (cat == "politics") return "landmark";
          if (cat == "entertainment") return "theater-masks";
          if (cat == "sports") return "football-ball";
          if (cat == "world news") return "globe-americas";
          if (cat == "business") return "coins";
          return "newspaper";
      },
      bestImage: function(story) {
          if (story == null || story[0] == null) {return "/noimage.png";}
          
          for (var i = 0; i < story.length; i++) {
            var next_story = story[i];
            if ('media_content' in next_story && next_story['media_content'] != "") {
                try {
                    var content = JSON5.parse(next_story['media_content']);
                    if ('url' in content[0]) { return content[0].url; }
                } catch {
                    console.log("Unable to parse:: " + next_story['media_content']);
                    return "/noimage.png";
                }
            }
            if ('media_thumbnail' in next_story && next_story['media_thumbnail'] != "") {
                    var thumbnail = JSON5.parse(next_story['media_thumbnail']);
                    if ('url' in thumbnail[0]) { return thumbnail[0].url; }
            }
            if ('links' in next_story && next_story['links'] != "") {
                var links = JSON5.parse(next_story['links'])
                for (let link of links) {
                    if ('rel' in link && link.rel == 'enclosure' && 'type' in link && link.type.substring(0, 5) == 'image') {
                        return link.href;
                    }
                }
            }
          }
          return "/noimage.png";
      }

  },
  mounted () {
    this.loading = true;
    Axios.get('http://api.drewesnews.com/story/' + this.$route.params.id)
    .then(Response => (this.story = Response.data.body))
    .finally(() => {
        this.loading = false
    })
  },
  props: {
      doRefresh: Boolean,
      checkedCategories: Array,
      checkedSources: Array
  },
  watch: {
      doRefresh: function(state) {
          if (state) {
                this.loading = true;
                Axios.get('http://api.drewesnews.com/story/' + this.$route.params.id, {
                    params: {
                        num_stories: 50,
                        dupes: false,
                        categories: JSON.stringify(this.checkedCategories),
                        sources: JSON.stringify(this.checkedSources)
                    }
                })
                .then(Response => (this.stories = Response.data.body))
                .finally(() => {
                    this.loading = false
                })
              this.$emit('refreshed');
          }
      }
  }
}
</script>

<style scoped>
.related {
  margin-bottom: 1.5rem;
}
.wordscores {
    font-size: 7pt;
    padding-top: 10px;
    padding-left: 20px;
    padding-bottom: 10px;
}
.crop {
    object-fit: cover;
    width: 4000px;
    height: 226px;
}
</style>