<template>
    <mdb-container>
        <div class="d-flex justify-content-center">
            <div class="spinner-grow text-success position-absolute" role="status" style="width: 5rem; height: 5rem; margin-top:150px;" v-if="loading">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <template v-for="story in stories">
          <div v-if="story.stories.length > 1" :key="story.group_id">
            <mdb-row>
                <mdb-col lg="4" xl="3">
                    <mdb-view class="rounded z-depth-1-half mb-lg-0 mb-4" hover>
                        <img class="img-fluid crop" :src="bestImage(story.stories)" alt="Story Image"/>
                        <a>
                            <mdb-mask overlay="white-slight" waves/>
                        </a>
                    </mdb-view>
                </mdb-col>
                <mdb-col lg="8" xl="9">
                    <a class="indigo-text">
                        <h6 class="font-weight-bold mb-3">
                        <mdb-icon v-if="'category' in story.stories[0]" :icon="categoryToIcon(story.stories[0].category)" class="pr-2" />{{story.stories[0].category}}</h6>
                    </a>
                    <span class="font-weight-bold dark-grey-text">
                        {{story.stories[0].published}}
                    </span>
                    <p>
                        {{story.stories[0].rss_source}}
                    </p>
                    <h3 class="font-weight-bold dark-grey-text mb-3 p-0">
                        <strong>{{story.stories[0].title}}</strong>
                    </h3>
                    <a :href="story.stories[0].link" target="_blank" rel="noopener noreferrer"><mdb-btn color="indigo" size="md" class="waves-light">Read more</mdb-btn></a>
                    <a :href="'story/' + story.stories[0].id" target="_blank" rel="noopener noreferrer"><mdb-btn color="cyan" size="md" class="waves-light"><mdb-icon icon="search" class="pr-2" />Details</mdb-btn></a>
                </mdb-col>
            </mdb-row>
            <mdb-row>
                <div v-if="'word_scores' in story.stories[0]" class="wordscores">
                    <span v-for="word in parseJson(story.stories[0].word_scores)" :key=word.M.word.S>
                        // {{word.M.word.S}} ({{Math.round(word.M.score.N * 100)}}%)
                    </span>
                </div>
            </mdb-row>         
            <mdb-row v-for="rel in getSubStories(story.stories)" :key="rel.story_id">
                <mdb-col md="2" class="text-sm-right">
                    <mdb-badge color="default">{{rel.rss_source}}</mdb-badge> <mdb-badge :color="percentColor(Math.round((rel.sim_distance) * 100))" v-if="'sim_distance' in rel">{{Math.round((rel.sim_distance) * 100)}}% match</mdb-badge>
                </mdb-col>
                <mdb-col md="10" style="padding-left: 0px">
                    <a :href="'story/' + rel.id" target="_blank" rel="noopener noreferrer"><mdb-icon icon="search" class="pr-2" /></a> <a :href="rel.link" target="_blank" rel="noopener noreferrer">{{rel.title}}</a> - {{rel.published}}
                </mdb-col>
            </mdb-row>
            <hr class="my-5" />
          </div>
        </template>
    </mdb-container>
</template>

<script>
import Axios from 'axios'
import { mdbContainer, mdbBadge, mdbRow, mdbCol, mdbMask, mdbIcon, mdbView, mdbBtn } from 'mdbvue';
const JSON5 = require('json5')
export default {
  name: 'Home',
    components: {
        mdbContainer,
        mdbBadge,
        mdbRow,
        mdbCol,
        mdbMask,
        mdbIcon,
        mdbView,
        mdbBtn
    },
  data () {
    return {
      stories: [],
      loading: false
    }
  },
  methods: {
      percentColor: function(percentVal) {
          if (percentVal >= 60) return "success";
          if (percentVal >= 40) return "warning";
          return "danger";
      },
      parseJson: function(json) {
          try {
            return JSON5.parse(json);
          } catch {
              console.log("Unable to read JSON:: " + json);
          }
      },
      getSubStories: function(stories) {
          if (stories.length <= 1) { return []; }
          
          return stories.slice(1);
      },
      categoryToIcon: function(category) {
          var cat = category.toLowerCase()
          if (cat == "politics") return "landmark";
          if (cat == "entertainment") return "theater-masks";
          if (cat == "sports") return "football-ball";
          if (cat == "world news") return "globe-americas";
          if (cat == "business") return "coins";
          return "newspaper";
      },
      bestImage: function(story) {
          if (story == null || story[0] == null) {return "noimage.png";}
          
          for (var i = 0; i < story.length; i++) {
            var next_story = story[i];
            if ('media_content' in next_story && next_story['media_content'] != "") {
                try {
                    var content = JSON5.parse(next_story['media_content']);
                    if ('url' in content[0]) { return content[0].url; }
                } catch {
                    console.log("Unable to parse:: " + next_story['media_content']);
                    return "noimage.png";
                }
            }
            if ('media_thumbnail' in next_story && next_story['media_thumbnail'] != "") {
                    var thumbnail = JSON5.parse(next_story['media_thumbnail']);
                    if ('url' in thumbnail[0]) { return thumbnail[0].url; }
            }
            if ('links' in next_story && next_story['links'] != "") {
                var links = JSON5.parse(next_story['links'])
                for (let link of links) {
                    if ('rel' in link && link.rel == 'enclosure' && 'type' in link && link.type.substring(0, 5) == 'image') {
                        return link.href;
                    }
                }
            }
          }
          return "noimage.png";
      }
  },
  mounted () {
    this.loading = true;
    Axios.get('http://api.drewesnews.com/?num_stories=50&dupes=false')
    .then(Response => (this.stories = Response.data.body.stories_db))
    .finally(() => {
        this.loading = false
    })
  },
  props: {
      doRefresh: Boolean,
      checkedCategories: Array,
      checkedSources: Array
  },
  watch: {
      doRefresh: function(state) {
          if (state) {
                this.loading = true;
                Axios.get('http://api.drewesnews.com/', {
                    params: {
                        num_stories: 50,
                        dupes: false,
                        categories: JSON.stringify(this.checkedCategories),
                        sources: JSON.stringify(this.checkedSources)
                    }
                })
                .then(Response => (this.stories = Response.data.body.stories_db))
                .finally(() => {
                    this.loading = false
                })
              this.$emit('refreshed');
          }
      }
  }
}
</script>

<style scoped>
.related {
  margin-bottom: 1.5rem;
}
.wordscores {
    font-size: 7pt;
    padding-top: 10px;
    padding-left: 20px;
    padding-bottom: 10px;
}
.crop {
    object-fit: cover;
    width: 4000px;
    height: 226px;
}
</style>