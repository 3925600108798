import Vue from 'vue';
import Router from 'vue-router';
import Story from '@/components/Story'
import Home from '@/components/Home'

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/story/:id',
      name: "Story",
      component: Story
    }
  ]
});
